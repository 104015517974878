export class SegmentDomain {
     
    private id: string; 
    private segmentId: string; 
    private domain: string; 
    private createdAt: Date;

    constructor( id: string, segmentId: string, domain: string, createdAt: Date,) {
        this.id = id;
        this.segmentId = segmentId;
        this.domain = domain;
        this.createdAt = createdAt;
    }

    
    public Id = (): string => {
        return this.id
    }
    public SegmentId = (): string => {
        return this.segmentId
    }
    public Domain = (): string => {
        return this.domain
    }
    public CreatedAt = (): Date => {
        return this.createdAt
    }
}