import { DictionariessRepository } from "../../repository/api/dictionaries/dictionaries";
import { actionCreators } from "../../state";

export class DictionaryUseCases {
    private api: DictionariessRepository;
    private stateActions: typeof actionCreators;

    constructor(api: DictionariessRepository, stateActions: typeof actionCreators) {
        this.api = api;
        this.stateActions = stateActions;
    }

    public ReadCityList = async () => {
        let response = await this.api.GetCityList()
        return response
    }

    public ReadAmoStatusList = async () => {
        let response = await this.api.GetStatusList()
        return response
    }

}