import axios from "axios";
import { Lead } from "../../../domain/lead/lead";
import { Configuration, LeadApi } from "../generated";
import { LeadInterface } from "../../../useCase/lead/adapters/interface";

export class LeadsRepository implements LeadInterface {
    private service: LeadApi;

    constructor() {
        this.service = new LeadApi(new Configuration(), process.env.REACT_APP_BACKEND_URL)
    }
    

    async ReadLeadsOfSegmentByDate(segmentId: UniqueId, date: Date): Promise<string[] | Error> {
        try {
            let response = await this.service.apiLeadSegmentIdDateGet(segmentId, date.toISOString(), { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                
                return response.data!
            } else {
                if (axios.isAxiosError(response)) {
                    return Error(response?.message)
                }
                return Error("something went wrong")
            }
        } catch (e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }

            return Error(error)
        }
    }

    async ReadNotDefinedLines(date: Date): Promise<string[] | Error> {
        try {
            let response = await this.service.apiLeadNotDefinedDateGet(date.toISOString(), { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                
                return response.data!
            } else {
                if (axios.isAxiosError(response)) {
                    return Error(response?.message)
                }
                return Error("something went wrong")
            }
        } catch (e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }

            return Error(error)
        }
    }
}