import { useContext, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { UseCasesContext } from "../../../../context/useCases"
import { FiledType, Form, Field } from "../../../components/form"
import { Menu } from "../../../components/menu"
import { DomainLeadType } from "../../../../repository/api/generated"
import { AmoStatus } from "../../../../domain/amostatus/amostatus"

export const IntegrationParamsEdit = () => {
    const navigate = useNavigate();
    let { id } = useParams();
    const [cityOptions, setCityOptions] = useState<{ value: number, title: string }[]>([])
    const [pipelinesList, setPipelinesList] = useState<{ value: number, title: string }[]>([])
    const [statusesList, setStatusesList] = useState<{ value: number, title: string }[]>([])
    const [statusesListAll, setStatusesListAll] = useState<AmoStatus[]>([])


    let useCases = useContext(UseCasesContext)
    const [form, setForm] = useState<{
        leadType: string;
        pipelineId: number,
        statusId: number,
        cityId: number,
        entityId: number,
        tag: string,
        utmSource: string,
        perevodKc: boolean,
    }>({
        leadType: "",
        pipelineId: 0,
        statusId: 0,
        cityId: 0,
        entityId: 0,
        tag: "",
        utmSource: "",
        perevodKc: false,
    })

    const submit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        useCases?.segmentUseCase.UpdateSegmentIntegration(id!, form.leadType!, form.pipelineId!, form.statusId!, form.cityId!, form.entityId!, form.tag!, form.utmSource!, form.perevodKc!,  () => { navigate(-1) })
    }

    const updateForm = (name: string, value: any) => {
        setForm({
            ...form,
            [name]: value,
        })
    }

    const readSegmentById = async (id: UniqueId) => {
        let response = await useCases?.segmentUseCase.ReadSegmentById(id)
        if (response instanceof Error) {
            
        } else {
            setForm({
                leadType: response?.LeadType()!,
                pipelineId: response?.PipelineId()!,
                statusId: response?.StatusId()!,
                cityId: response?.CityId()!,
                entityId: response?.EntityId()!,
                tag: response?.Tag()!,
                utmSource: response?.UtmSource()!,
                perevodKc: response?.PerevodKc()!,
            })
        }
    }

    const readAmoStatuses = async () => {
        let response = await useCases?.dictionariesUseCase.ReadAmoStatusList()
        if (response instanceof Error) {

        } else {
            let pipelines: { value: number, title: string }[] = []
            setStatusesListAll([...response!])
            let addedPipelines: string[] = [];
            response!.forEach((amoStatus) => {
                if (addedPipelines.indexOf(amoStatus.PipelineName()) === -1) {
                    pipelines.push({
                        value: amoStatus.PipelineId(),
                        title: amoStatus.PipelineName()
                    })
                    addedPipelines.push(amoStatus.PipelineName())
                }
            })
            setPipelinesList(pipelines)
            if (form.pipelineId !== 0) {
                let statuses: { value: number, title: string }[] = []
                let items = response!.filter((amoStatus) => amoStatus.PipelineId() === form.pipelineId)
                items.forEach((status) => {
                    statuses.push({ value: status.StatusId(), title: status.StatusName() })
                })
                setStatusesList([...statuses])
            }
        }
    }

   


    const readCityList = async () => {
        let response = await useCases?.dictionariesUseCase.ReadCityList()
        if (response instanceof Error) {

        } else {
            let options: any[] = []

            response!.forEach((city) => {
                options.push({ value: city.Id(), title: city.Name() })
            })
            setCityOptions(options)
        }
    }

    

    useEffect(() => {
        if (id) {
            readSegmentById(id)
        }
    }, [id])

    useEffect(() => {
        readAmoStatuses()
        readCityList()
    }, [])


    useEffect(() => {
        let statuses: { value: number, title: string }[] = []
        let items = statusesListAll!.filter((amoStatus) => amoStatus.PipelineId() === form.pipelineId)
        items.forEach((status) => {
            statuses.push({ value: status.StatusId(), title: status.StatusName() })
        })
        setStatusesList([...statuses])
    }, [form.pipelineId, statusesListAll])



    const formatDate = (day: Date) => {
        const yyyy = day.getFullYear();
        let mm = (day.getMonth() + 1).toString(); // Months start at 0!
        let dd = day.getDate().toString();

        if (dd.length == 1) dd = '0' + dd;
        if (mm.length == 1) mm = '0' + mm;

        return dd + '.' + mm + '.' + yyyy;
    }

    const pickDefaultInputs = () => {
        let fields: Field[] = []
        if (form.pipelineId !== 0 && statusesList.length > 0 ) {
            fields = [
                {
                    name: "leadType",
                    title: "Тип лида",
                    placeholder: "Выберите тип лида",
                    value: form.leadType,
                    type: FiledType.Select,
                    options: [
                        {
                            title: "Обычный",
                            value: "default"
                        },
                        {
                            title: "Авто",
                            value: "avto"
                        },
                        {
                            title: "ЖК",
                            value: "jk"
                        },
                    ]
                },
                {
                    name: "pipelineId",
                    title: "Воронка",
                    placeholder: "Выберите воронку",
                    value: form.pipelineId,
                    type: FiledType.SelectInt,
                    options: pipelinesList,
                },
                {
                    name: "statusId",
                    title: "Статус",
                    placeholder: "Выберите статус",
                    value: form.statusId,
                    type: FiledType.SelectInt,
                    options: statusesList,
                },
                {
                    name: "cityId",
                    title: "Город",
                    placeholder: "Выберите город",
                    value: form.cityId,
                    type: FiledType.SelectInt,
                    options: cityOptions,
                },
                {
                    name: "tag",
                    title: "Тег",
                    placeholder: "Введите Тег",
                    value: form.tag,
                    type: FiledType.Text,
                },
                {
                    name: "utmSource",
                    title: "utm_source",
                    placeholder: "Введите utm_source",
                    value: form.utmSource,
                    type: FiledType.Text,
                },
                {
                    name: "perevodKc",
                    title: "Перевод КЦ",
                    placeholder: "Перевод КЦ",
                    value: form.perevodKc,
                    type: FiledType.CheckBox,
                },
            ]
        } else {
            fields = [
                {
                    name: "leadType",
                    title: "Тип лида",
                    placeholder: "Выберите тип лида",
                    value: form.leadType,
                    type: FiledType.Select,
                    options: [
                        {
                            title: "Обычный",
                            value: "default"
                        },
                        {
                            title: "Авто",
                            value: "avto"
                        },
                        {
                            title: "ЖК",
                            value: "jk"
                        },
                    ]
                },
                {
                    name: "pipelineId",
                    title: "Воронка",
                    placeholder: "Выберите воронку",
                    value: form.pipelineId,
                    type: FiledType.SelectInt,
                    options: pipelinesList,
                },
                {
                    name: "statusId",
                    title: "Статус",
                    placeholder: "Выберите статус",
                    value: form.statusId,
                    type: FiledType.SelectInt,
                    options: statusesList,
                },
                {
                    name: "cityId",
                    title: "Город",
                    placeholder: "Выберите город",
                    value: form.cityId,
                    type: FiledType.SelectInt,
                    options: cityOptions,
                },
                {
                    name: "tag",
                    title: "Тег",
                    placeholder: "Введите Тег",
                    value: form.tag,
                    type: FiledType.Text,
                },
                {
                    name: "utmSource",
                    title: "utm_source",
                    placeholder: "Введите utm_source",
                    value: form.utmSource,
                    type: FiledType.Text,
                },
                {
                    name: "perevodKc",
                    title: "Перевод КЦ",
                    placeholder: "Перевод КЦ",
                    value: form.perevodKc,
                    type: FiledType.CheckBox,
                },

            ]
        }

        if (form.leadType !== "" && form.leadType !== "default") {
            fields.push({
                name: "entityId",
                title: "Id",
                placeholder: "Введите Id",
                value: form.entityId,
                type: FiledType.Number,
            })
        }

        return fields
    } 




    return (
        <div className="layout-page" >
            <Menu />
            <div className="content-wrapper" >
                <div className="container-xxl flex-grow-1 container-p-y">
                    <div className="row mx-1">
                        <div className="col-sm-12 col-md-12">
                            <h4 className="fw-bold py-3 mb-4"><span className="text-muted fw-light">Сегменты /</span> Редактировать сегмент</h4>
                        </div>
                        <div className="card">
                            <div className="card-header d-flex justify-content-between align-items-center">
                                <h5 className="mb-0">Редактирование сегмента</h5>
                            </div>
                            <div className="card-body">
                                <Form
                                    state={{
                                        loading: false,
                                        error: "",
                                    }}

                                    submit={submit}

                                    fields={pickDefaultInputs()}
                                    btnSmall={true}
                                    submitBtnTitle={"Сохранить"}
                                    updateForm={updateForm}
                                />
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
