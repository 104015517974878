import { useContext, useState } from "react";
import { ApiApp, Clipboard, ClipboardList, ClipboardTypography, Copy, DotsVertical, Edit, Phone, Trash, WorldWww } from 'tabler-icons-react'
import { UseCasesContext } from "../../../../context/useCases";
import { Link, useParams } from "react-router-dom";
import { SegmentWithLeadsCount } from "../../../../domain/segment/segment_with_leads_count";

export const TableRow = (props: { segment: SegmentWithLeadsCount, date: Date, last: boolean }) => {
    const { id } = useParams()
    const [showMenu, setShowMenu] = useState(false)
    let useCases = useContext(UseCasesContext)
    const [integrationActivity, setIntegrationActivity] = useState(props.segment.IsIntegrationActive())
    const [loadingActivity, setLoadingActivity] = useState(false)
    const [loadingIntegrationActivity, setLoadingIntegrationActivity] = useState(false)
    const [error, setError] = useState<string | null>()

    const formatDate = (day: Date) => {
        const yyyy = day.getFullYear();
        let mm = (day.getMonth() + 1).toString(); // Months start at 0!
        let dd = day.getDate().toString();

        if (dd.length == 1) dd = '0' + dd;
        if (mm.length == 1) mm = '0' + mm;

        return dd + '.' + mm + '.' + yyyy.toString().substring(2);
    }

    const formatDateWithTime = (day: Date) => {
        const yyyy = day.getFullYear();
        let mm = (day.getMonth() + 1).toString(); // Months start at 0!
        let dd = day.getDate().toString();

        if (dd.length == 1) dd = '0' + dd;
        if (mm.length == 1) mm = '0' + mm;

        //dd + '.' + mm + '.' + yyyy.toString().substring(2) + " " +
        return  day.getHours() + ":" + day.getMinutes();
    }

    const changeActivityIntegration = async () => {
        setLoadingIntegrationActivity(true)
        let result = await useCases?.segmentUseCase.ToggleSegmentIntegrationActivity(props.segment.Id(), !integrationActivity)
        
        if (result instanceof Error) {
            console.log(result.message)
            setError(result.message)
            setLoadingIntegrationActivity(false)

            setTimeout(()=>{
                setError("")
            },5000)
            return
        } else {
            setIntegrationActivity(result!.IsIntegrationActive())
            setLoadingIntegrationActivity(false)
        }
    }



    return (<tr>
        <td>{props.segment.Name()}</td>

        <td>
            {error ?
                <div className="alert alert-danger" role="alert">Ошибка: {error}</div>
                : loadingActivity?<img src="img/loader.gif" style={{maxHeight:50}} />:<div className="form-check form-switch mb-2">
                    <input className="form-check-input" type="checkbox" id="flexSwitchCheckChecked" onChange={(e) => { changeActivityIntegration() }} checked={integrationActivity} />
                    <label className="form-check-label" htmlFor="flexSwitchCheckChecked">{integrationActivity ? "Вкл" : "Выкл"}</label>
                </div>}
        </td>
        <td>{props.segment.LeadsCount()}</td>
        <td>{props.segment.LastProcessedLeadDate() ? formatDateWithTime(props.segment.LastProcessedLeadDate()!):""}</td>
        <td>{props.segment.LeadsCount() && props.segment.LeadsCount()! > 0 ? <a href="#" onClick={async (e) => {
            e.preventDefault();

            let leads = await useCases?.leadsUseCase.ReadLeadsOfSegmentByDate(props.segment.Id(), props.date)
            if (leads instanceof Error) {

            } else {

                let csvContent = "data:text/csv;charset=utf-8,"

                csvContent += leads!.join("\n")
                var encodedUri = encodeURI(csvContent);

                var link = document.createElement('a');
                link.href = encodedUri;
                link.target = "_blank"
                link.download = props.segment.Name() +"_"+ formatDate(props.date)+ ".csv";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }

        }} >Скачать</a> : <></>}</td>
        <td>{formatDate(props.segment.CreatedAt())}</td>
        <td>
            <div className="dropdown">
                <button type="button" onClick={() => { setShowMenu(!showMenu) }} className="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown" aria-expanded="false">
                    <DotsVertical />
                </button>
                <div className={showMenu ? "dropdown-menu show" : "dropdown-menu "} style={showMenu ? props.last ? { position: "absolute", inset: "auto auto 0px -20%", margin: "0px", transform: "translate3d(0px, -20.5px, 0px)" } : { position: "absolute", marginLeft: "-40%", } : { display: "none" }}>
                    <Link className="dropdown-item d-flex" to={"/segment/integration/" + props.segment.Id()}><ApiApp className="me-1" size={20} /> Параметры Интеграции</Link>
                    <Link className="dropdown-item d-flex" to={"/segment/input-params/" + props.segment.Id()}><ClipboardList className="me-1" size={20} /> Домены/Номера</Link>
                    <Link className="dropdown-item d-flex" to={"/segment/copy/" + props.segment.Id()}><Copy className="me-1" size={20} /> Копировать</Link>
                    <a className="dropdown-item d-flex" href="#" onClick={(e) => { e.preventDefault(); useCases?.segmentUseCase.DeleteSegment(props.segment.Id()); setShowMenu(false) }}><Trash className="me-1" size={20} /> Удалить</a>
                </div>
            </div>
        </td>
    </tr>)
}
