import { combineReducers } from "redux";
import loginReducer from './loginReducer';
import segmentReducer from "./segmentReducer";

const reducers = combineReducers({
    login: loginReducer,
    segement: segmentReducer,
})

export default reducers;
export type RootState = ReturnType<typeof reducers>;