import { Dispatch } from "react"
import { SegmentActionTypes } from "../action-types";
import { SegmentActions } from "../actions"
import { Segment } from "../../domain/segment/segment";
import { SegmentWithLeadsCount } from "../../domain/segment/segment_with_leads_count";

export const SendSegmentListRequest = () => {
    return async (dispatch: Dispatch<SegmentActions>) => {
        dispatch({
            type: SegmentActionTypes.SEGMENT_REQUEST_SEND,
          });
    }
}

export const SegmentListSuccess = (segments: SegmentWithLeadsCount[], count: number) => {
    return async (dispatch: Dispatch<SegmentActions>) => {
        dispatch({
            type: SegmentActionTypes.SEGMENT_SUCCESS,
            payload: {segments: segments, count: count},
        });
    }
}

export const SegmentListError = (error:string) => {
    return async (dispatch: Dispatch<SegmentActions>) => {
        dispatch({
            type: SegmentActionTypes.SEGMENT_ERROR,
            payload: error,
        });
    }
}

export const SegmentCreateRequest = () => {
    return async (dispatch: Dispatch<SegmentActions>) => {
        dispatch({
            type: SegmentActionTypes.SEGMENT_CREATE_REQUEST,
          });
    }
}

export const SegmentCreateSuccess = (segment: Segment) => {
    return async (dispatch: Dispatch<SegmentActions>) => {
        dispatch({
            type: SegmentActionTypes.SEGMENT_CREATE_SUCCESS,
            payload: segment,
          });
    }
}

export const SegmentCreateError = (message: string) => {
    return async (dispatch: Dispatch<SegmentActions>) => {
        dispatch({
            type: SegmentActionTypes.SEGMENT_CREATE_ERROR,
            payload: message,
          });
    }
}


export const SegmentUpdateRequest = () => {
    return async (dispatch: Dispatch<SegmentActions>) => {
        dispatch({
            type: SegmentActionTypes.SEGMENT_UPDATE_REQUEST,
          });
    }
}

export const SegmentUpdateSuccess = (category: Segment) => {
    return async (dispatch: Dispatch<SegmentActions>) => {
        dispatch({
            type: SegmentActionTypes.SEGMENT_UPDATE_SUCCESS,
            payload: category,
          });
    }
}

export const SegmentUpdateError = (message: string) => {
    return async (dispatch: Dispatch<SegmentActions>) => {
        dispatch({
            type: SegmentActionTypes.SEGMENT_UPDATE_ERROR,
            payload: message,
          });
    }
}


export const SegmentDeleteRequest = () => {
    return async (dispatch: Dispatch<SegmentActions>) => {
        dispatch({
            type: SegmentActionTypes.SEGMENT_DELETE_REQUEST,
          });
    }
}

export const SegmentDeleteSuccess = (id: UniqueId) => {
    return async (dispatch: Dispatch<SegmentActions>) => {
        dispatch({
            type: SegmentActionTypes.SEGMENT_DELETE_SUCCESS,
            payload: id,
          });
    }
}

export const SegmentDeleteError = (message: string) => {
    return async (dispatch: Dispatch<SegmentActions>) => {
        dispatch({
            type: SegmentActionTypes.SEGMENT_DELETE_ERROR,
            payload: message,
          });
    }
}