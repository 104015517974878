import { LeadsRepository } from "../../repository/api/lead/lead";
import { actionCreators } from "../../state";


export class LeadUseCases {
    private api: LeadsRepository;
    private stateActions: typeof actionCreators;

    constructor(api: LeadsRepository, stateActions: typeof actionCreators) {
        this.api = api;
        this.stateActions = stateActions;
    }

    public ReadLeadsOfSegmentByDate = async (segmentId: UniqueId, date: Date) => {
        let response = await this.api.ReadLeadsOfSegmentByDate(segmentId, date)
        return response
    }

    public ReadNotDefinedLines = async (date: Date) => {
        let response = await this.api.ReadNotDefinedLines(date)
        return response
    }

}
