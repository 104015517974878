import axios from "axios";
import { Configuration, DictionariesApi } from "../generated";
import { City } from "../../../domain/city/city";
import { AmoStatus } from "../../../domain/amostatus/amostatus";
import { DictionariesInterface } from "../../../useCase/dictionaries/adapters/dictionary";

export class DictionariessRepository implements DictionariesInterface {
    private service: DictionariesApi;

    constructor() {
        this.service = new DictionariesApi(new Configuration(), process.env.REACT_APP_BACKEND_URL)
    }

    async GetCityList(): Promise<City[] | Error> {
        let cityList: City[] = []
        let response = await this.service.apiDictionariesCityGet({headers: {"Authorization": localStorage.getItem("token")}})

        if (response.status === 200) {
            response.data.list.forEach((cityInp)=>{
                let city = new City(cityInp.id, cityInp.name)
                cityList.push(city)
            })
        }

        if (response.status === 401) {
            return Error("error authorization")
        }

        return cityList
    }

    async GetStatusList(): Promise<AmoStatus[] | Error> {
        let amoStatusList: AmoStatus[] = []
        let response = await this.service.apiDictionariesAmostatusGet({headers: {"Authorization": localStorage.getItem("token")}})

        if (response.status === 200) {
            response.data.list.forEach((amoStatusInp)=>{
                let amoStatus = new AmoStatus(amoStatusInp.statusId, amoStatusInp.statusName, amoStatusInp.pipelineId, amoStatusInp.pipelineName)
                amoStatusList.push(amoStatus)
            })
        }

        if (response.status === 401) {
            return Error("error authorization")
        }
        
        return amoStatusList
    }
    
}