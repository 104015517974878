import { PieChart } from 'react-feather';
import { Logout, ChartArcs3, CreditCard, Users, ChartInfographic } from 'tabler-icons-react';

export const Items = [

    {
        title: "Сегменты",
        icon: <PieChart size={24} className="menu-icon"  />,
        href: "/segment",
    },

    {
        title: "Отчет",
        icon: <ChartInfographic size={24} className="menu-icon"  />,
        isAdmin: false,
        href: "/report",
    },

    
    {
        title: "Выход",
        icon: <Logout size={24} className="menu-icon" />,
        href: "/exit",
    },

]