export class SegmentPhone {
     
    private id: string; 
    private segmentId: string; 
    private phone: string; 
    private createdAt: Date;

    constructor( id: string, segmentId: string, phone: string, createdAt: Date,) {
        this.id = id;
        this.segmentId = segmentId;
        this.phone = phone;
        this.createdAt = createdAt;
    }

    
    public Id = (): string => {
        return this.id
    }
    public SegmentId = (): string => {
        return this.segmentId
    }
    public Phone = (): string => {
        return this.phone
    }
    public CreatedAt = (): Date => {
        return this.createdAt
    }
}