import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Login } from "../../pages/auth/login/login";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import { useContext, useEffect } from "react";
import { UseCasesContext } from "../../../context/useCases";
import { SegmentlList } from "../../pages/segments/list";
import { SegmentAdd } from "../../pages/segments/add";
import { IntegrationParamsEdit } from "../../pages/segments/integration-params-edit";
import { InputParamsEdit } from "../../pages/segments/input-params-edit";
import { SegmentCopy } from "../../pages/segments/copy";
import { Report } from "../../pages/segments/report";

export const RouterComponent = () => {
  let useCases = useContext(UseCasesContext)

  const login = useTypedSelector(({ login }) => {
    return login
  })

  useEffect(() => {
    useCases?.authUseCase.CheckAuthorization()
    setInterval(()=>{
      useCases?.authUseCase.CheckAuthorization()
    },10000)
  }, [])
 
  
  return (<Router basename="/">
    <Routes>
      {login?.authorized ? <>
        <Route path="/report" element={<Report />} />

        <Route path="/segment/input-params/:id" element={<InputParamsEdit />} />
        <Route path="/segment/integration/:id" element={<IntegrationParamsEdit />} />
        <Route path="/segment/copy/:id" element={<SegmentCopy />} />
        <Route path="/segment/add" element={<SegmentAdd />} />
        <Route path="*" element={<SegmentlList />} />
      </> : <>
        <Route path="*" element={<Login />} />
      </>}
    </Routes>
  </Router>
  )
}