export enum SegmentActionTypes {
    SEGMENT_REQUEST_SEND = 'segment_request_send',
    SEGMENT_ERROR = 'segment_error',
    SEGMENT_SUCCESS = 'segment_success',
    
    SEGMENT_CREATE_REQUEST = 'segment_create_request',
    SEGMENT_CREATE_SUCCESS = 'segment_create_success',
    SEGMENT_CREATE_ERROR = 'segment_create_error',

    SEGMENT_DELETE_REQUEST = 'segment_delete_request',
    SEGMENT_DELETE_SUCCESS = 'segment_delete_success',
    SEGMENT_DELETE_ERROR = 'segment_delete_error',

    SEGMENT_UPDATE_REQUEST = 'segment_update_request',
    SEGMENT_UPDATE_SUCCESS = 'segment_update_success',
    SEGMENT_UPDATE_ERROR = 'segment_update_error',
}