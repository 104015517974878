export class Segment {
     
    private id: string; 
    private name: string; 
    private createdAt: Date; 
    private modfiedAt: Date; 
    private isIntegrationActive: boolean; 
    private leadType: string; 
    private pipelineId: number; 
    private statusId: number; 
    private cityId: number; 
    private entityId: number; 
    private tag: string; 
    private utmSource: string; 
    private perevodKc: boolean;

    constructor( id: string, name: string, createdAt: Date, modfiedAt: Date, isIntegrationActive: boolean, leadType: string, pipelineId: number, statusId: number, cityId: number, entityId: number, tag: string, utmSource: string, perevodKc: boolean,) {
        this.id = id;
        this.name = name;
        this.createdAt = createdAt;
        this.modfiedAt = modfiedAt;
        this.isIntegrationActive = isIntegrationActive;
        this.leadType = leadType;
        this.pipelineId = pipelineId;
        this.statusId = statusId;
        this.cityId = cityId;
        this.entityId = entityId;
        this.tag = tag;
        this.utmSource = utmSource;
        this.perevodKc = perevodKc;
        
    }

    
    public Id = (): string => {
        return this.id
    }
    public Name = (): string => {
        return this.name
    }
    public CreatedAt = (): Date => {
        return this.createdAt
    }
    public ModfiedAt = (): Date => {
        return this.modfiedAt
    }
    public IsIntegrationActive = (): boolean => {
        return this.isIntegrationActive
    }
    public LeadType = (): string => {
        return this.leadType
    }
    public PipelineId = (): number => {
        return this.pipelineId
    }
    public StatusId = (): number => {
        return this.statusId
    }
    public CityId = (): number => {
        return this.cityId
    }
    public EntityId = (): number => {
        return this.entityId
    }
    public Tag = (): string => {
        return this.tag
    }
    public UtmSource = (): string => {
        return this.utmSource
    }
    public PerevodKc = (): boolean => {
        return this.perevodKc
    }
}