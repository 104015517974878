import { createContext } from 'react'
import { AuthorizationUseCases } from '../useCase/authorization/authorization';
import { SegmentUseCases } from '../useCase/segment/adapters/segment';
import { DictionaryUseCases } from '../useCase/dictionaries/dictionaries';
import { LeadUseCases } from '../useCase/lead/lead';

interface UseCasesContextInterface {
    authUseCase: AuthorizationUseCases;
    segmentUseCase: SegmentUseCases;
    dictionariesUseCase: DictionaryUseCases;
    leadsUseCase: LeadUseCases;
}

export const UseCasesContext = createContext<UseCasesContextInterface | null>(null)
