import { Auth } from "../repository/api/auth/auth"
import { DictionariessRepository } from "../repository/api/dictionaries/dictionaries"
import { LeadsRepository } from "../repository/api/lead/lead"
import { SegmentsRepository } from "../repository/api/segment/segment"
import { AuthorizationUseCases } from "../useCase/authorization/authorization"
import { DictionaryUseCases } from "../useCase/dictionaries/dictionaries"
import { LeadUseCases } from "../useCase/lead/lead"
import { SegmentUseCases } from "../useCase/segment/adapters/segment"


export const useUseCases = (actions:any) => {
    const authUseCase = new AuthorizationUseCases(new Auth(), actions)
    const segmentUseCase = new SegmentUseCases(new SegmentsRepository(), actions);
    const dictionariesUseCase = new DictionaryUseCases(new DictionariessRepository(), actions);
    const leadsUseCase = new LeadUseCases(new LeadsRepository(), actions);

    return {authUseCase, segmentUseCase, dictionariesUseCase, leadsUseCase}
}
