
export class AmoStatus {
    public statusId: number;
    public statusName: string;
    public pipelineId: number;
    public pipelineName: string;

    constructor(statusId:number, statusName:string, pipelineId: number, pipelineName:string) {
        this.statusId = statusId
        this.statusName = statusName
        this.pipelineId = pipelineId
        this.pipelineName = pipelineName
    }

    public StatusId = (): number => {
        return this.statusId
    }

    public StatusName = (): string => {
        return this.statusName
    }

    public PipelineId = (): number => {
        return this.pipelineId
    }

    public PipelineName = (): string => {
        return this.pipelineName
    }
}