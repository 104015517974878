import { Name } from "./types";

export class City {
    public id: number;

    public name: Name;


    constructor(id:number, name:string) {
        this.name = name
        this.id = id
    }

    public Id = (): number => {
        return this.id
    }

    public Name = (): Name => {
        return this.name
    }
}