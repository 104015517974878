import { useContext, useEffect, useState } from "react"
import { UseCasesContext } from "../../../../context/useCases"
import { useTypedSelector } from "../../../../hooks/useTypedSelector"
import { Link, useParams } from "react-router-dom"

import { Menu } from "../../../components/menu"


export const Report = () => {
    const [error, setError] = useState<string>()
    let { id } = useParams();

    const [filter, setFilter] = useState<{
        month: number,
        year: number,
    }>({
        month: new Date().getMonth(),
        year: new Date().getFullYear(),
    })



    let useCases = useContext(UseCasesContext)


    const download = async () => {
        let response = await useCases?.segmentUseCase.ReadReport(filter.month, filter.year)
        if (response instanceof Error) {
             
        } else {
            var link = document.createElement('a');
            link.href = process.env.REACT_APP_BACKEND_URL + "/api/files/report.xlsx";
            link.target = "_blank"
            //link.download = "result.xlsx";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
        
        
    }

    useEffect(() => {

    }, [])

    return (
        <div className="layout-page" >
            <Menu />
            <div className="content-wrapper" >
                <div className="container-xxl flex-grow-1 container-p-y">
                    <div className="row mx-1">
                        <div className="col-sm-12 col-md-4">
                            <h4 className="fw-bold py-3 mb-4"><span className="text-muted fw-light">Отчет по лидам </span></h4>
                        </div>

                        <div className="card mb-4">
                            <div className="card-body">
                                <div className="row mb-3" style={{ alignItems: "center" }}>
                                    <div className="col-12 col-sm-6 col-lg-4 justify-content-end">
                                        <div className="mb-3">
                                            <label htmlFor={"month_select"} className="form-label">Выберите месяц</label>
                                            <select onChange={(e) => { setFilter({ ...filter, month: parseInt(e.target.value) }) }} value={filter.month.toString()} className="form-select mb-3">
                                                <option value={"1"}>Январь</option>
                                                <option value={"2"}>Февраль</option>
                                                <option value={"3"}>Март</option>
                                                <option value={"4"}>Апрель</option>
                                                <option value={"5"}>Май</option>
                                                <option value={"6"}>Июнь</option>
                                                <option value={"7"}>Июль</option>
                                                <option value={"8"}>Август</option>
                                                <option value={"9"}>Сентябрь</option>
                                                <option value={"10"}>Октябрь</option>
                                                <option value={"11"}>Ноябрь</option>
                                                <option value={"12"}>Декабрь</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-6 col-lg-4 justify-content-end">
                                        <div className="mb-3">
                                            <label htmlFor={"year_select"} className="form-label">Выберите год</label>
                                            <select onChange={(e) => { setFilter({ ...filter, year: parseInt(e.target.value) }) }} value={filter.year.toString()} className="form-select mb-3">
                                                <option value={new Date().getFullYear() - 2}>{new Date().getFullYear() - 2}</option>
                                                <option value={new Date().getFullYear() - 1}>{new Date().getFullYear() - 1}</option>
                                                <option value={new Date().getFullYear()}>{new Date().getFullYear()}</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className="col-12 col-sm-6 col-lg-4 justify-content-end">
                                        <button className="btn btn-primary d-grid w-100 waves-effect waves-light" type="submit" onClick={(e) => { e.preventDefault(); download() }}>Скачать</button>
                                    </div>
                                </div>
                            </div>
                        </div>



                    </div>
                </div>
            </div>
        </div>
    )

}